// Your custom variables
$primary-color: #006891 !default;
$primary-color-dark: #004F6A !default;
$secondary-color: #FD1501 !default;
$secondary-color-dark: #CB1501 !default;
$default-color: #688082 !default;
$default-color-dark: #364E50 !default;
$info-color: #33b5e5 !default;
$info-color-dark: #0099cc !default;
$success-color: #00c851 !default;
$success-color-dark: #007e33 !default;
$warning-color: #ffbb33 !default;
$warning-color-dark: #ff8800 !default;
$danger-color: #ff3547 !default;
$danger-color-dark: #cc0000 !default;
$elegant-color: #2e2e2e !default;
$elegant-color-dark: #212121 !default;
$stylish-color: #4b515d !default;
$stylish-color-dark: #3e4551 !default;
$unique-color: #3f729b !default;
$unique-color-dark: #1c2331 !default;
$special-color: #37474f !default;
$special-color-dark: #263238 !default;
$white: #ffffff !default;
$black: #000000 !default;

$table-sm-padding-y: 0.4rem;

$stepper-horizontal-breakpoint: 768px !default;

$checkbox-filled-in-transition: .25s !default;
$checkbox-filled-in-transition-border: border $checkbox-filled-in-transition;
$checkbox-filled-in-transition-background-color: background-color $checkbox-filled-in-transition;
$checkbox-filled-in-transition-width: width $checkbox-filled-in-transition;
$checkbox-filled-in-transition-height: height $checkbox-filled-in-transition;
$checkbox-filled-in-transition-top: top $checkbox-filled-in-transition;
$checkbox-filled-in-transition-left: left $checkbox-filled-in-transition;

$stepper-horizontal-small-li-after-height: calc(100% - 30px) !default;
$stepper-horizontal-small-li-after-left: 2.29rem !default;
$stepper-vertical-li-after-top: 3.74rem !default;

$input-range-thumb-top: -30px !default;
$input-range-transform-origin: -20% 50% !default;

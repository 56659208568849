/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

// Material checkbox styles
.form-check-input[type="checkbox"],
label.btn input[type="checkbox"] {
    // Text Label Style
    + label {
        position: relative;
        display: inline-block;
        height: $checkbox-label-height;
        padding-left: $checkbox-label-pl;
        line-height: $checkbox-label-line-height;
        cursor: pointer;
        user-select: none;

        &.form-check-label-left {
            padding: 0 35px 0 0 !important;

            &:before {
                right: 0;
                left: 100% !important;
                transform: translateX(-100%);
            }
        }
    }

    // Checkbox feature
    + label:before,
    &:not(.filled-in) + label:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: $checkbox-label-before-after-width;
        height: $checkbox-label-before-after-height;
        margin-top: $checkbox-label-before-after-mt;
        content: "";
        border: $checkbox-label-before-after-border-width solid $checkbox-label-before-after-border-color;
        border-radius: $checkbox-label-before-after-border-radius;
        transition: $checkbox-label-before-after-transition;
    }

    &:not(.filled-in) + label:after {
        border: 0;
        transform: scale(0);
    }

    &:not(:checked):disabled + label:before {
        background-color: $input-disabled-solid-color;
        border: none;
    }

    &:checked {
        + label {
            &:before {
                top: $checkbox-checked-label-before-top;
                left: $checkbox-checked-label-before-left;
                width: $checkbox-checked-label-before-width;
                height: $checkbox-checked-label-before-height;
                border-top: $checkbox-label-before-after-border-width solid transparent;
                border-right: $checkbox-label-before-after-border-width solid $radio-fill-color;
                border-bottom: $checkbox-label-before-after-border-width solid $radio-fill-color;
                border-left: $checkbox-label-before-after-border-width solid transparent;
                transform: $checkbox-checked-label-before-transform;
                transform-origin: $checkbox-checked-label-before-transform-origin;
                backface-visibility: hidden;
            }

            &.form-check-label-left {
                &:before {
                    transform: translateX(0) rotateZ(40deg);
                    transform-origin: 0 0;
                }
            }
        }

        &:disabled + label:before {
            border-right: $checkbox-label-before-after-border-width solid $input-disabled-solid-color;
            border-bottom: $checkbox-label-before-after-border-width solid $input-disabled-solid-color;
        }
    }

    // Indeterminate checkbox
    &:indeterminate {
        + label {
            &:before {
                top: $checkbox-indeterminate-label-before-top;
                left: $checkbox-indeterminate-label-before-left;
                width: $checkbox-indeterminate-label-before-width;
                height: $checkbox-indeterminate-label-before-height;
                border-top: none;
                border-right: $checkbox-label-before-after-border-width solid $radio-fill-color;
                border-bottom: none;
                border-left: none;
                transform: $checkbox-indeterminate-label-before-transform;
                transform-origin: $checkbox-checked-label-before-transform-origin;
                backface-visibility: hidden;
            }

            &.form-check-label-left {
                &:before {
                    top: 0;
                    transform-origin: 0 0;
                }
            }
        }

        &:disabled + label:before {
            background-color: transparent;
            border-right: $checkbox-label-before-after-border-width solid $input-disabled-color;
        }
    }

    // Filled in Style
    &.filled-in {
        + label:after {
            border-radius: $checkbox-filled-in-border-radius;
        }

        + label:before,
        + label:after {
            position: absolute;
            left: 0;
            z-index: 1;
            content: "";

            /* .1s delay is for check animation */
            transition: $checkbox-filled-in-transition-border, $checkbox-filled-in-transition-background-color, $checkbox-filled-in-transition-width, $checkbox-filled-in-transition-height, $checkbox-filled-in-transition-top, $checkbox-filled-in-transition-left;
        }

        // Unchecked style
        &:not(:checked) + label:before {
            top: $checkbox-filled-in-unchecked-label-before-top;
            left: $checkbox-filled-in-unchecked-label-before-left;
            width: 0;
            height: 0;
            border: $checkbox-filled-in-unchecked-label-before-border-width solid transparent;
            transform: rotateZ(37deg);
            transform-origin: $checkbox-checked-label-before-transform-origin;
        }

        &:not(:checked) + label:after {
            top: 0;
            z-index: 0;
            width: $checkbox-filled-in-unchecked-label-after-width;
            height: $checkbox-filled-in-unchecked-label-after-height;
            background-color: transparent;
            border: $checkbox-label-before-after-border-width solid $radio-empty-color;
        }

        // Checked style
        &:checked {
            + label:before {
                top: 0;
                left: $checkbox-filled-in-checked-label-before-left;
                width: $checkbox-filled-in-checked-label-before-width;
                height: $checkbox-filled-in-checked-label-before-height;
                border-top: $checkbox-label-before-after-border-width solid transparent;
                border-right: $checkbox-label-before-after-border-width solid $white-base;
                border-bottom: $checkbox-label-before-after-border-width solid $white-base;
                border-left: $checkbox-label-before-after-border-width solid transparent;
                transform: $checkbox-filled-in-unchecked-label-before-transform;
                transform-origin: $checkbox-checked-label-before-transform-origin;
            }

            + label:after {
                top: 0;
                z-index: 0;
                width: $checkbox-filled-in-unchecked-label-after-height;
                height: $checkbox-filled-in-unchecked-label-after-width;
                background-color: $secondary-color;
                border: $checkbox-label-before-after-border-width solid $secondary-color;
            }
        }

        &.filled-in-danger {
            &:checked + label:after {
                background-color: $red-base;
                border-color: $red-base;
            }
        }
    }

    // Disabled style
    &:disabled {
        &:not(:checked) {
            + label:before {
                background-color: $input-disabled-solid-color;
                border-color: $input-disabled-solid-color;
            }

            + label:after {
                background-color: $input-disabled-solid-color;
                border-color: $input-disabled-solid-color;
            }
        }

        &:checked {
            + label:before {
                background-color: transparent;
            }

            + label:after {
                background-color: $input-disabled-solid-color;
                border-color: $input-disabled-solid-color;
            }
        }
    }
}

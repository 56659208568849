// These settings will be only for one version
// .card {
//   &.card-cascade {
//     &.panel-cascade {
//       .view {
//         text-align: center;
//         color: $panel-cascade-view-color;
//       }
//       .list-group {
//         .list-group-item {
//           margin-bottom: 0;
//           border: 0;
//           border-bottom: 1px solid $panel-cascade-list-group-item-border-bottom;
//           color: $panel-cascade-list-group-item-color;
//           &:hover {
//             background-color: $panel-cascade-list-group-item-bgc;
//           }
//         }
//       }
//     }
//   }
// }

// Remove default Radio Buttons
.form-check-input:not(:checked),
.form-check-input:checked {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

// Radio buttons
.form-check-input[type="radio"],
label.btn input[type="radio"] {

  &:not(:checked) + label,
  &:checked + label {
    position: relative;
    display: inline-block;
    height: $radio-label-height;
    padding-left: $radio-label-pl;
    line-height: $radio-label-line-height;
    cursor: pointer;
    user-select: none;
    transition: $radio-label-transition;
  }

  & + label:before,
  & + label:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: $radio-label-before-after-width;
    height: $radio-label-before-after-height;
    margin: $radio-label-before-after-margin;
    content: "";
    transition: $radio-label-transition;
  }

  // Unchecked styles
  &:not(:checked) + label:before,
  &:not(:checked) + label:after,
  &:checked + label:before,
  &:checked + label:after,
  &.with-gap:checked + label:before,
  &.with-gap:checked + label:after {
    border-radius: $radio-label-border-radius;
  }

  &:not(:checked) + label:before,
  &:not(:checked) + label:after {
    border: $radio-label-border-width solid $radio-empty-color;
  }

  &:not(:checked) + label:after {
    transform: scale(0);
  }

  // Checked styles
  &:checked + label:before {
    border: $radio-label-border-width solid transparent;
  }

  &:checked + label:after,
  &.with-gap:checked + label:before,
  &.with-gap:checked + label:after {
    border: $radio-label-border-width solid $radio-fill-color;
  }

  &:checked + label:after,
  &.with-gap:checked + label:after {
    background-color: $radio-fill-color;
  }

  &:checked + label:after {
    transform: $radio-label-checked-transform;
  }

  // Radio With gap
  &.with-gap:checked + label:after {
    transform: $radio-label-with-gap-checked-transform;
  }

  // Disabled Radio With gap
  &.with-gap:disabled:checked + label:before {
    border: $radio-label-border-width solid $input-disabled-color;
  }

  &.with-gap:disabled:checked + label:after {
    background-color: $input-disabled-color;
    border: none;
  }

  // Disabled style
  &:disabled:not(:checked) + label:before,
  &:disabled:checked + label:before {
    background-color: transparent;
    border-color: $input-disabled-color;
  }

  &:disabled + span {
    color: $input-disabled-color;
  }

  &:disabled:not(:checked) + span:before {
    border-color: $input-disabled-color;
  }

  &:disabled:checked + span:after {
    background-color: $input-disabled-color;
    border-color: $input-disabled-solid-color;
  }

  &:checked + label:after .disabled-material {
    background-color: rgba($radio-fill-color, .2);
  }
  
  &:focus {
    + label:before {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25); 
    }
  }

}

.md-disabled::after {
  background-color: rgba($radio-fill-color, .5) !important;
  border-color: rgba($radio-fill-color, .2) !important;
}

.md-disabled::before {
  border-color: rgba($radio-fill-color, .25) !important;
}
